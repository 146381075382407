import { IconButton, TableCell } from '@mui/material';
import { sentenceCase } from 'change-case';
import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import Iconify from '../Iconify';
import Label from '../Label';
import { statusToColor } from '../Status';
import ActionMenu from './ActionMenu';





const CustomTableCell = (type, data) => {

  switch (type) {
    case 'string':
      return <TableCell align="left">{data}</TableCell>
    case 'number':
      return <TableCell align="left">{data}</TableCell>
    case 'status':
      return (<TableCell align="left">
        <Label variant="ghost" color={statusToColor(data)}>
          {data.toString()}
        </Label>
      </TableCell>)
    case 'datetime':
      return <TableCell align='left'>{moment(data).format('DD/MM/YYYY')}</TableCell>
    case 'actionbutton':
      return <TableCell align='left'><Link to={data.url}>
        <IconButton>{data.icon || <Iconify icon="akar-icons:pencil" width={24} height={24} />}</IconButton></Link></TableCell>
    case 'callbackAction':
      return (<TableCell align='left'>
        <IconButton onClick={() => data.callback(data.params)}>{data.icon}</IconButton></TableCell>)
    case 'actionMenu':
      return (
        <TableCell align='left'>
          <ActionMenu menuItems={data.menuItems} />
        </TableCell>
      )
    default:
      return <TableCell align="left">{data}</TableCell>
  }
}

export default CustomTableCell