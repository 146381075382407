import { Grid, CardContent, TextField, Checkbox, FormControl, InputLabel, Select, MenuItem, ListItemText, Card, Typography, Box, Divider, TextareaAutosize, FormControlLabel } from '@mui/material'
import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup';
import moment from 'moment';
import tz from 'moment-timezone';
import LoadingButton from '@mui/lab/LoadingButton';
import useAxios from 'src/hooks/useAxios';
import communication from 'src/constants/Communication';
import _ from 'lodash';
import Loader from 'src/components/Loader';
import { statusToColor, statusToTextColor } from 'src/components/Status';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import Label from 'src/components/Label';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react';
import { localTimezone } from '../../constants/config'
import { toast } from 'react-toastify';
import { authenticatedInstance } from 'src/utils/axiosInstance';
import { useState } from 'react';

const View = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [isSubmitting,setSubmitting] = useState(false)




    const revokeAccess = async () => {
        setSubmitting(true)
        try {
            const response = await authenticatedInstance.request({
                method: 'POST',
                url: communication.users.revoke,
                data: {
                    id
                }
            })
            toast.success(response.data.message)
            navigate(0);
        } catch (error) {
            console.log(error)
        } finally {
            setSubmitting(false)
        }
    }

 


    const [data, loading, error] = useAxios({
        method: 'GET',
        url: communication.users.get,
        params: { searchCriteria: { id } },

    })

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <Loader />

    }



    const userDetail = _.get(data, 'results[0]', {})


    const fields = [
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "courseName", label: "Course" },
        { key: "role", label: "Role" ,type:'label'},
    ]

    const convertType = (value, type) => {
        switch (type) {
            case 'label':
                return <Label variant='ghost' color={statusToColor(value)}>{value}</Label>
            case 'datetime':
                return <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap> {moment.unix(value).format("DD/MM/YYYY h:mm a")}</Typography>
            default:
                return <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>{value}</Typography>
        }
    }

    const renderDetails = (field) => {
        return (
            <Grid item xs={4} sm={4}>
                <Typography variant="h6" noWrap>
                    {field['label']}
                </Typography>
                {convertType(userDetail[field['key']], field.type)}
            </Grid>
        )
    }



    return (

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                            <Grid container spacing={2}>
                                {fields.map((f) => (
                                    renderDetails(f)
                                ))}
                                <Grid item xs={4} sm={4}>
                                    <LoadingButton onClick={revokeAccess} type='submit' loading={isSubmitting}
                                        variant='contained'
                                        color='error'
                                        sx={{ marginBottom: 7 }}>
                                        Revoke Access
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                    </CardContent>
                </Card>
            </Grid>


        </Grid>

    )
}

export default View