import axios from 'axios';
import _ from 'lodash';
import communication from 'src/constants/Communication';
import { authenticatedInstance, authInstance, unAuthenticatedInstance } from './axiosInstance';

export const login = async (email, password) => {
    try {
        const { data } = await unAuthenticatedInstance.request({
            url: communication.login,
            method: 'POST',
            data: {
                email: email,
                password: password
            }
        })

        if (data) {
            sessionStorage.setItem('token', JSON.stringify(data.tokens))
            sessionStorage.setItem('user', JSON.stringify(data.user))

        }

    } catch (error) {
        console.log("🚩 ~ file: authentication.js ~ line 8 ~ login ~ error", error)
    }
}


export const logout = async () => {
    try {

        let token = sessionStorage.getItem('token')
        token = JSON.parse(token)


        const res = await authenticatedInstance.request({
            url: communication.logout,
            method: 'POST',
            data: {
                refreshToken: token.refresh.token
            }
        })

        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user')


    } catch (error) {
        sessionStorage.removeItem('token')
        console.log("🚩 ~ file: authentication.js ~ line 8 ~ login ~ error", error)
    }
}


