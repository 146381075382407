import { useState } from "react";
import { useSearchParams,useNavigate } from "react-router-dom";
import TableBasic from "src/components/DataTable/TableBasic";
import Loader from "src/components/Loader";
import communication from "src/constants/Communication";
import useAxios from "src/hooks/useAxios";
import * as yup from 'yup';

export default function Users() {
  const [searchFields, setSearchFields] = useState({})
  const [searchParams,setSearchParams] = useSearchParams();
  const [paginationProps, setPaginationProps] = useState({
    page: +searchParams.get('page'),
    rowsPerPage: 10
  })

  const gridColumns = [
    { id: 'firstName', label: 'Name', alignRight: false, type: 'string' },
    { id: 'email', label: 'Email', alignRight: false, type: 'string' },
    { id: 'country', label: 'Country', alignRight: false, type: 'string' },
    { id: 'isPaid', label: 'Is Paid', alignRight: false, type: 'status' },
    { id: 'createdAt', label: 'Registered At', alignRight: false, type: 'datetime' },
    { id: 'action', label: 'Action', alignRight: false, type: 'actionbutton' },

  ];

  const [data, loading, error] = useAxios({
    url: communication.getRegisterations,
    method: 'GET',
    params: { limit: paginationProps.rowsPerPage, page: paginationProps.page + 1, searchCriteria: searchFields,sortBy:'createdAt:desc' }
  })

  

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <Loader />

  }

  const search = (values) => {
    const searchFilter = {}
    for (let key of Object.keys(values)) {
      if (values[key] != '') {
        searchFilter[key] = values[key]
        if (key == 'isPaid') {
          searchFilter[key] = values[key] == 'PAID' ? true : false
        }
      }
    }

    setSearchFields(searchFilter)
    setPaginationProps((prevState) => ({
      ...prevState,
      page: 0
    }));
    setSearchParams({'page':0})

  }

  const handleChangePage = (event, newPage) => {
    
    setPaginationProps((prevState) => ({
      ...prevState,
      page: newPage
    }));
    setSearchParams({'page':newPage})
  };

  const handleChangeRowsPerPage = (event) => {
    setPaginationProps((prevState) => ({
      ...prevState,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10)
    }));
  };

  const searchCriteria = {
    initialValues: {
      email: _.get(searchFields, 'email', ''),
      firstName: _.get(searchFields, 'firstName', ''),
      referenceNo: _.get(searchFields, 'referenceNo', ''),
      isPaid: _.get(searchFields, 'isPaid', '')
    },
    searchCallback: search,

    filterCritera: {
      email: {
        type: 'email',
        name: 'email',
        label: 'Email'
      },
      firstName: {
        type: 'text',
        name: 'firstName',
        label: 'First Name'
      },
      referenceNo: {
        type: 'text',
        name: 'referenceNo',
        label: 'Reference No'
      },
      status: {
        type: 'select',
        name: 'isPaid',
        label: 'Status',
        options: [{
          value: '',
          name: 'ALL'
        },
        {
          value: 'PAID',
          name: 'PAID'
        },
        {
          value: 'NOT PAID',
          name: 'NOT PAID'
        }]
      }
    },
    validationSchema: yup.object({
      email: yup.string(),
      firstName: yup.string(),
      referenceNo: yup.string(),
      status: yup.string(),
    })
  };

  const gridData = data.results.map((f) => ({
    ...f,
    action: {
      url: '/dashboard/useredit/' + f.referenceNo,
    }
  }))


  return <TableBasic tableName={'Users'} gridData={gridData} gridColumns={gridColumns} searchCriteria={searchCriteria} onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    page={paginationProps.page}
    count={data.totalResults}
    rowsPerPage={paginationProps.rowsPerPage} />
}