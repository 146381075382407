const statusToColor = (status) => {
    switch (status) {
        case true:
            return 'success'
        case false:
            return 'info'
        case 'PAID':
            return 'success'
        case 'NOT PAID':
            return 'info'
        case 'Created':
            return 'secondary'
        case 'Pending':
            return 'info'
        case 'Completed':
            return 'success'
        case 'Issued':
            return 'success'
        case 'revoked':
            return 'error'
        default:
            return 'info'

    }
}


const statusToTextColor = (status) => {
    switch (status) {
        case 'Pending':
            return 'text.primary'
        case 'Completed':
            return 'success.main'
        default:
            return 'text.secondary'

    }
}



module.exports = {
    statusToColor,
    statusToTextColor
}