import { Grid, CardContent, TextField, Checkbox, FormControl, InputLabel, Select, MenuItem, ListItemText, Card, Typography, Box, Divider, TextareaAutosize, FormControlLabel } from '@mui/material'
import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup';
import moment from 'moment';
import tz from 'moment-timezone';
import LoadingButton from '@mui/lab/LoadingButton';
import useAxios from 'src/hooks/useAxios';
import communication from 'src/constants/Communication';
import _ from 'lodash';
import Loader from 'src/components/Loader';
import { statusToColor, statusToTextColor } from 'src/components/Status';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import Label from 'src/components/Label';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import { useEffect } from 'react';
import {localTimezone} from '../../constants/config'
import { toast } from 'react-toastify';
import { authenticatedInstance } from 'src/utils/axiosInstance';

const View = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    


    const validationSchema = yup.object({
        link: yup.string().required(),
        schedule: yup.date().required()
    });

    const initalValues = {
        link: '',
        schedule: ''
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initalValues,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            issueMeetingLinkCallback(values, setSubmitting)
        },

    });

    


    const issueMeetingLinkCallback = async (values,setSubmitting)=>{
        try {
            const response = await authenticatedInstance.request({
                method: 'POST',
                url: communication.issueMeetingLink,
                data: {
                    id: bookingDetail.id,
                    link: values.link,
                    schedule: moment(values.schedule).utc().unix(),
                }
            })
            toast.success(response.data.message)
            navigate(0);
        } catch (error) {
            console.log(error)
        }finally{
            setSubmitting(false)
        }
    }
  

   
    const [data, loading, error] = useAxios({
        method: 'GET',
        url: communication.getBookings,
        params: { searchCriteria: { id} },

    })

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <Loader />

    }



    const bookingDetail = _.get(data, 'results[0]', {})

    initalValues['schedule'] = moment.unix(bookingDetail.schedule).format('YYYY-MM-DDTHH:mm')

    const fields = [
        { key: "name", label: "Name" },
        { key: "email", label: "Email" },
        { key: "phoneNumber", label: "Phone" },
        { key: "courseName", label: "Course" },
        { key: "age", label: "Age" },
        { key: "countryCode", label: "Country" },
        { key: "schedule", label: "Scheduled Time",type:"datetime" },
        { key: "timezone", label: "Time Zone" },
        { key: "status", label: "Status", type: "label" },
    ]

    const convertType = (value,type)=>{
        switch (type) {
            case 'label':
                return <Label variant='ghost' color={statusToColor(value)}>{value}</Label> 
            case 'datetime':
                return <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap> {moment.unix(value).format("DD/MM/YYYY h:mm a")}</Typography>
            default:
                return <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>{value}</Typography>
        }
    }

    const renderDetails = (field) => {
        return (
            <Grid item xs={4} sm={3}>
                <Typography variant="h6" noWrap>
                    {field['label']}
                </Typography>
                {convertType(bookingDetail[field['key']],field.type)}               
            </Grid>
        )
    }
    
   

    return (

        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                {fields.map((f) => (
                                    renderDetails(f)
                                ))}
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    
                                    <TextField multiline minRows={3} size='small' fullWidth label='Zoom Link' value={formik.values.link}
                                        name='link'
                                        onChange={formik.handleChange}
                                        error={formik.touched.link && Boolean(formik.errors.link)}
                                        helperText={'Paste zoom meeting link'} />

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField size='small' fullWidth label='Re-schedule' value={formik.values.schedule}
                                        name='schedule'
                                        type="datetime-local"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={formik.handleChange}
                                        error={formik.touched.schedule && Boolean(formik.errors.schedule)}
                                        helperText={`Local time in ${localTimezone} is ${moment.tz(formik.values.schedule,bookingDetail.timezone).tz(localTimezone).format('DD/MM/YYYY h:mm a')}`}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} sm={6} display={'flex'} justifyContent={'flex-end'}>
                                    <LoadingButton type='submit' loading={formik.isSubmitting}
                                        variant='contained'
                                        
                                        sx={{ marginBottom: 7 }}>
                                        Update
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>

        </Grid>

    )
}

export default View