import {
    Box,
    CircularProgress,
    Typography,
    CardContent,

} from '@mui/material'
import React from 'react'
import * as yup from 'yup';
import _ from 'lodash';
import { useEffect, useState } from 'react'
import TableBasic from '../../components/DataTable/TableBasic';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'


import { authenticatedInstance } from 'src/utils/axiosInstance';
import Iconify from 'src/components/Iconify';
import useAxios from 'src/hooks/useAxios';
import Loader from 'src/components/Loader';
import ConfirmationDialog from 'src/components/Dialogs/ConfirmationDialog';
import communication from 'src/constants/Communication';

const List = () => {
    const navigate = useNavigate()


    const [searchFields, setSearchFields] = useState({})

    const [paginationProps, setPaginationProps] = useState({
        page: 0,
        rowsPerPage: 10
    })
    const gridColumns = [
        { label: 'Name', id: 'name', type: 'string' },
        { label: 'Email', id: 'email', type: 'string' },
        { label: 'Course', id: 'courseName', type: 'string' },
        { id: 'action', label: 'Action', alignRight: false, type: 'actionMenu' },


    ]

    const handleChangePage = (event, newPage) => {

        setPaginationProps((prevState) => ({
            ...prevState,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setPaginationProps((prevState) => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        }));
    };


    const search = (values) => {
        const searchFilter = {}
        for (let key of Object.keys(values)) {
            if (values[key] != '') {
                searchFilter[key] = values[key]

            }
        }

        setSearchFields(searchFilter)
    }

    const searchCriteria = {
        initialValues: {
            email: _.get(searchFields, 'email', ''),
            name: _.get(searchFields, 'name', ''),
        },
        searchCallback: search,
        filterCritera: {
            name: {
                type: 'text',
                name: 'name',
                label: 'Name'
            },
            email: {
                type: 'text',
                name: 'email',
                label: 'Email'
            },


        },
        validationSchema: yup.object({
            name: yup.string(),
            email: yup.string(),
        })
    };

    const [data, loading, error] = useAxios({
        method: 'GET',
        url: communication.users.get,
        params: { limit: paginationProps.rowsPerPage, page: paginationProps.page + 1, searchCriteria: searchFields },

    })

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <Loader />

    }

    const handleViewCallback = ({ id }) => {
        navigate('/portal-users/' + id)
    }


    const gridData = data.results.map((f) => ({
        ...f,
        action: {
            menuItems: [
                { params: { id: f.id }, name: "View", callback: handleViewCallback, icon: 'mdi:eye-arrow-right' },
            ]
        }
    }))











    return (
        <>
            <TableBasic
                tableName={'Portal Users'}
                gridColumns={gridColumns}
                gridData={gridData}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={paginationProps.page}
                count={data.totalResults}
                rowsPerPage={paginationProps.rowsPerPage}
                searchCriteria={searchCriteria}


            />
        </>

    )
}



export default List
