import { Button, Card, CardContent, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import Scrollbar from '../Scrollbar'

const Filter = ({ formik, filterCritera }) => {
    return (
        <CardContent sx={{
            width: 280,
            px: 2
        }}>
            <Typography variant='subtitle1'>Filter Critera</Typography>

            <Scrollbar>
                <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <Grid container spacing={2} py={2}>
                        {Object.keys(filterCritera).map((f, index) => {
                            if (filterCritera[f].type == 'select') {
                                return (
                                    <Grid key={index} item xs={12} md={12}>
                                        <TextField
                                            size='small'
                                            
                                            name={filterCritera[f].name}
                                            type={filterCritera[f].type}
                                            value={formik.values[filterCritera[f].name]}
                                            label={filterCritera[f].label}
                                            onChange={formik.handleChange}
                                            select
                                            fullWidth
                                        >
                                            {filterCritera[f].options.map(d => (
                                                <MenuItem value={d.value}>{d.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                )
                            } else {
                                return (
                                    <Grid key={index} item xs={12} md={12}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            placeholder={filterCritera[f].label}
                                            color='secondary'
                                            name={filterCritera[f].name}
                                            type={filterCritera[f].type}
                                            value={formik.values[filterCritera[f].name]}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                )
                            }
                        })}
                        <Grid item xs={12} md={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
                        <Button type='submit' fullWidth  variant='contained'>Apply</Button>
                        <Button type='reset' fullWidth variant='text' color='error'>Reset</Button>
                        </Stack>
                           
                        </Grid>
                    </Grid>
                </form>
            </Scrollbar>
        </CardContent>
    )
}

export default Filter