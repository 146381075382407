import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Stack, Typography } from '@mui/material';
import Iconify from '../Iconify';
import { width } from '@mui/system';

export default function ActionMenu({menuItems}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>{<Iconify icon="mdi:dots-horizontal" width={24} height={24} />}</IconButton>
      
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map((m)=>(
           <MenuItem sx={{
            
            width: '120px'
           }} onClick={()=>{m.callback(m.params)}}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
            <Iconify icon={m.icon||'akar-icons:pencil'} width={16} height={16} />
              <Typography fontSize={14}>{m.name}</Typography>
            </Stack>
           </MenuItem>
        ))}
       
      </Menu>
    </div>
  );
}
