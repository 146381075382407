import communication from '../constants/Communication';
import axios from 'axios';
import _ from 'lodash';
import {toast} from 'react-toastify'

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_BASE_URL_DEV : process.env.REACT_APP_API_BASE_URL_PROD;
const defaultOptions = {
    baseURL,
};


export const authenticatedInstance = axios.create(defaultOptions) 
authenticatedInstance.interceptors.request.use(async (request) => {

    let token = sessionStorage.getItem('token')
    if (token) {
        token = JSON.parse(sessionStorage.getItem('token'))
        request.headers.Authorization = `Bearer ${token.access.token}`;
    }

    return request;
});
authenticatedInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        toast.error(error.response.data.message)
    },
);

export const unAuthenticatedInstance = axios.create(defaultOptions) 
unAuthenticatedInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        toast.error(error.response.data.message)
    },
);
  


