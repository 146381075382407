const communication = {
    login: 'auth/login',
    logout: 'auth/logout',
    refreshToken: 'auth/refresh-tokens',
    getGroups: '/groups/get',
    getCoupons:'/coupon/getCoupon',
    getRegisterations:'/registration/get',
    updateRegisterations:'/registration/update',
    getReports:'/reports/get',
    getBookings:'/bookings/get',
    issueMeetingLink: '/bookings/issue-link',
    executeQuery:'/reports/execute-query',
    users:{
        get:'/users/get',
        revoke: '/users/revoke'
    }



}

export default communication