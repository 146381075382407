// useAxios hook

import { useState, useEffect } from 'react';
import _ from 'lodash';
import { authenticatedInstance } from 'src/utils/axiosInstance';


const useAxios = (axiosParams) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [body, setBody] = useState({})
    const [params, setParams] = useState({})


    const fetchData = async (params) => {
        setBody(params.body)
        setParams(params.params)
        setLoading(true)
        setError('')
        setResponse(null)
        try {
            const result = await authenticatedInstance.request(params);
            setResponse(result.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {

        if (!_.isEqual(body, axiosParams.body) || !_.isEqual(params, axiosParams.params)) {
            fetchData(axiosParams);
        }

    }, [axiosParams.body, axiosParams.params]); // execute once only

    return [response, loading, error];
};

export default useAxios;