// component
import { currentUser } from 'src/hooks/useAuthentication';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

let groups = currentUser.get('groups')

let navConfig = []

for(let i of groups){
  for(let j of i.uiPermissions){
    navConfig.push({
      ...j,
      icon:getIcon(j.icon)
    })
  }
}



export default navConfig;
