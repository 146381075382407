import { LoadingButton } from '@mui/lab'
import { Button, Card, CardContent, Checkbox, FormControlLabel, Grid, InputLabel, TextField } from '@mui/material'
import React from 'react'

const Exporter = ({ formik }) => {


    return (
        <Card>
            <CardContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField disabled={formik.values.allRecords == true} fullWidth label='From Date' value={formik.values.fromDate}
                                name='fromDate'
                                type={'datetime-local'}
                                onChange={formik.handleChange}
                                error={formik.touched.fromDate && Boolean(formik.errors.fromDate)}
                                helperText={formik.touched.fromDate && formik.errors.fromDate} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField disabled={formik.values.allRecords == true} fullWidth label='To Date' value={formik.values.toDate}
                                name='toDate'
                                type={'datetime-local'}
                                onChange={formik.handleChange}
                                error={formik.touched.toDate && Boolean(formik.errors.toDate)}
                                helperText={formik.touched.toDate && formik.errors.toDate} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        
                        <FormControlLabel control={<Checkbox color='success' checked={formik.values.allRecords} />}
                            label="All Records"
                            name='allRecords'
                            onChange={formik.handleChange}
                            error={formik.touched.allRecords && Boolean(formik.errors.allRecords)}
                            helperText={formik.touched.allRecords && formik.errors.allRecords}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <LoadingButton type='submit' loading={formik.isSubmitting}
                            variant='contained'
                            sx={{ marginBottom: 7 }}>
                             Download CSV
                        </LoadingButton>

                       
                        </Grid>
                    </Grid>

                </form>
            </CardContent>
        </Card>
    )
}

export default Exporter