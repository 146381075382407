import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Typography, Button, Divider } from '@mui/material';
// component

import FilterButton from './FilterButton';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  flexGrow: 1,
  padding: theme.spacing(0, 1, 0, 3),
}));



// ----------------------------------------------------------------------

SearchFilter.propTypes = {
  formik: PropTypes.object,
  filterCritera: PropTypes.object,
  exportCSV: PropTypes.func
};

export default function SearchFilter({ formik, filterCritera, exportCSV, tableName,noRows }) {
  return (
    <>
      <RootStyle

      >
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {tableName}
        </Typography>
        <FilterButton  formik={formik} filterCritera={filterCritera} />
        {/* <Button sx={{ ml: 2 }} size='small' disabled={noRows} onClick={exportCSV} variant='contained'>Export CSV</Button> */}
       

      </RootStyle>
      <Divider /></>
  );
}
