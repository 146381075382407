import React from 'react'
import TableBasic from 'src/components/DataTable/TableBasic';

const Purchases = ({ data }) => {

    const tableHeaders = [
        { label: 'Invoice #', id: 'invoiceNumber', type: 'string' },
        { label: 'Courses', id: 'coursesName', type: 'string' },
        { label: 'Grand Total', id: 'grandTotal', type: 'string' },
        { label: 'Type', id: 'type', type: 'string' },
        { label: 'Currency', id: 'currency', type: 'string' },
        { label: 'Status', id: 'status', type: 'status' },
    ]

    return (
       
            <TableBasic
                gridColumns={tableHeaders}
                gridData={data}
                pagination={false}
                searchable={false}

            />
       
    )
}

export default Purchases