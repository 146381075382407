import React, { useState } from 'react'
import { Card, CardContent, CardHeader, Grid, Box, ImgStyled, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button, Divider, Tab, Tabs, CircularProgress } from "@mui/material"
import { styled } from '@mui/material/styles'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import MuiTab from '@mui/material/Tab'
import { toast } from "react-toastify"
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Iconify from 'src/components/Iconify'
import BasicDetails from 'src/sections/@dashboard/user/BasicDetails'
import useAxios from 'src/hooks/useAxios'
import Loader from 'src/components/Loader'
import { authenticatedInstance } from 'src/utils/axiosInstance'
import CourseDetails from 'src/sections/@dashboard/user/CourseDetails'
import IssueCoupon from 'src/sections/@dashboard/user/IssueCoupon'
import Purchases from 'src/sections/@dashboard/user/Purchases'
import communication from 'src/constants/Communication'

const UserEdit = () => {
    const { id: referenceNo } = useParams()
    const navigate = useNavigate()
    const Tab = styled(MuiTab)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            minWidth: 100
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 67
        }
    }))

    const TabName = styled('span')(({ theme }) => ({
        lineHeight: 1.71,
        fontSize: '0.875rem',
        marginLeft: theme.spacing(2.4),
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }))

    const [value, setValue] = useState('profile')




    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const updateCallback = async (values, resetForm) => {

        try {
            const response = await authenticatedInstance.request({
                method: 'POST',
                url: communication.updateRegisterations,
                data: {
                    referenceNo,
                    ...values
                }
            })
            toast.success(response.data.message)
            navigate(0);
        } catch (error) {
            console.log(error)
        }
    }


    const [data, loading, error] = useAxios({
        url: communication.getRegisterations,
        method: 'GET',
        params: {
            searchCriteria: {
                referenceNo: referenceNo
            }
        }
    })

    const [groups, groupsLoading, groupsError] = useAxios({
        url: communication.getGroups,
        method: 'GET',

    })

    const issueCouponCallback = async (values, resetForm) => {
        try {
            const response = await authenticatedInstance.request({
                method: 'POST',
                url: '/coupon/issueCoupon',
                data: {
                    email: data.results[0].email,
                    discount: values.discount,
                    expires: values.expires,
                }
            })
           
            toast.success(response.data.message)
            navigate(0);
        } catch (error) {
            console.log(error)

        }
    }

    if (loading || groupsLoading) {
        return <Loader />
    }

    if (error || groupsError) {
        return <Loader />

    }

    return (
        <Card>
            <TabContext value={value}>
                <TabList
                    onChange={handleChange}
                    aria-label='account-settings tabs'
                    sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
                >
                    <Tab
                        value='profile'
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Iconify icon="ant-design:profile-outlined" width={24} height={24} />
                                <TabName>Profile</TabName>
                            </Box>
                        }
                    />
                    <Tab
                        value='courses'
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Iconify icon="ph:books" width={24} height={24} />
                                <TabName>Courses</TabName>
                            </Box>
                        }
                    />

                    <Tab
                        value='coupon'
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Iconify icon="ri:coupon-3-line" width={24} height={24} />
                                <TabName>Issue Coupon</TabName>
                            </Box>
                        }
                    />

                    <Tab
                        value='purchases'
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Iconify icon="ri:cash-line" width={24} height={24} />
                                <TabName>Purchases</TabName>
                            </Box>
                        }
                    />



                </TabList>

                <TabPanel sx={{ p: 0 }} value='profile'>
                    <BasicDetails userDetails={data.results[0]} groups={groups} updateCallback={updateCallback} />
                </TabPanel>

                <TabPanel sx={{ p: 0 }} value='courses'>
                    <CourseDetails data={data.results[0].courses} />

                </TabPanel>

                <TabPanel sx={{ p: 0 }} value='coupon'>
                    <IssueCoupon issueCouponCallback={issueCouponCallback} />

                </TabPanel>

                <TabPanel sx={{ p: 0 }} value='purchases'>
                    <Purchases data={data.invoices.map((i)=>({...i,coursesName:i.courses.map((c)=>c.name).join(",")}))} />

                </TabPanel>


            </TabContext>
        </Card>
    )
}

export default UserEdit