import React from 'react'
import TableBasic from '../../../components/DataTable/TableBasic'

const CourseDetails = ({ data }) => {

    const tableHeaders = [
        { label: 'Name', id: 'courseName', type: 'string' },
        { label: 'Price', id: 'coursePrice', type: 'string' },
        { label: 'Currency', id: 'currency', type: 'string' },
        { label: 'Duration', id: 'duration', type: 'string' },
    ]

    return (
       
            <TableBasic
                gridColumns={tableHeaders}
                gridData={data}
                pagination={false}
                searchable={false}

            />
       
    )
}

export default CourseDetails