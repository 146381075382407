import { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment'
import { authenticatedInstance } from 'src/utils/axiosInstance';
import communication from 'src/constants/Communication';
import { useLocation } from 'react-router-dom'



export const currentUser ={
   get:(field)=>{
    return _.get(JSON.parse(sessionStorage.getItem('user')),field,'')
   }
}


const useAuthentication = () => {
    // check if token in session
    const [authenticated, setAuthneticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const location = useLocation();






    const getToken = () => {

        const token = sessionStorage.getItem('token')
        if (token) {
            return JSON.parse(sessionStorage.getItem('token'))
        }
        return null

    }



    const refreshToken = async (token) => {
        try {
            const { data } = await authenticatedInstance.request({
                url: communication.refreshToken,
                method: 'POST',
                data: {
                    refreshToken: token.refresh.token
                }
            })
            sessionStorage.setItem('token', JSON.stringify(data))
        } catch (error) {
            console.log("🚩 ~ file: useAuthentication.js ~ line 49 ~ refreshToken ~ error", error)
        }


    }

    useEffect(() => {
        const token = getToken()
        if (token) {
            if (moment.duration(moment(token.access.expires).diff(moment.now())).asSeconds() <= parseInt(process.env.REACT_APP_REFRESH_INTERVAL)) {
                refreshToken(token)
            }
        }
    }, [location])


    useLayoutEffect(() => {
        const token = getToken()
        if (token) {
            setAuthneticated(true)
        }
        setLoading(false)
    }, [])


    return [authenticated, loading]

}

export default useAuthentication;