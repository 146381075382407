import { Grid, CardContent, TextField, Button, CircularProgress } from '@mui/material'
import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup';
import moment from 'moment';
import { Box } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';

const IssueCoupon = ({ issueCouponCallback }) => {

    const validationSchema = yup.object({
        discount: yup.number().min(1).max(100).required(),
        expires: yup.date().min(moment()).required(),
    });

    const formik = useFormik({
        initialValues: {
            discount: 0,
            expires: '',

        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            
            issueCouponCallback(values, resetForm)
        },

    });

 
    

    return (
        <CardContent>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField InputLabelProps={{
                            shrink: true,
                        }} fullWidth
                            label='Discount'
                            type='number'
                            name='discount'
                            value={formik.values.discount}
                            onChange={formik.handleChange}
                            error={formik.touched.discount && Boolean(formik.errors.discount)}
                            helperText={formik.touched.discount && formik.errors.discount} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth InputLabelProps={{
                            shrink: true,
                        }} label='Discount Validity'
                            type='date'
                            name='expires'
                            value={formik.values.expires}
                            onChange={formik.handleChange}
                            error={formik.touched.expires && Boolean(formik.errors.expires)}
                            helperText={formik.touched.expires && formik.errors.expires} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <LoadingButton type='submit' loading={formik.isSubmitting}
                            variant='contained'
                            sx={{ marginBottom: 7 }}>
                            Create
                        </LoadingButton>

                    </Grid>
                </Grid>
            </form>

        </CardContent>
    )
}

export default IssueCoupon