const { Navigate } = require("react-router-dom");

const ProtectedRoute = ({ authenticated, loading, children }) => {
 
  

  if (loading && !authenticated) {
    return <h1>Loading...</h1>
  }

  if (!authenticated && !loading) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute