import React, { useState } from 'react'
import _ from 'lodash'
import { Card, TableContainer, Table, TableBody, TableRow, TableCell, Stack, Avatar, Typography, TablePagination } from '@mui/material'
import Scrollbar from '../Scrollbar'
import ListHead from './ListHead'
import users from 'src/_mock/user'
import UserMoreMenu from './UserMoreMenu'
import CustomTableCell from './CustomTableCell'
import SearchFilter from './SearchFilter'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import converter from 'json-2-csv'

const TableBasic = ({
    tableName,
    gridColumns,
    gridData,
    exportFields = [],
    searchCriteria = {
        initialValues: {},
        searchCallback: () => { },
        filterCritera: {},
        validationSchema: {}
    },
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    count,
    searchable = true,
    pagination = true
}) => {

    const TABLE_HEAD = gridColumns

    const formik = useFormik({
        initialValues: searchCriteria.initialValues,
        validationSchema: searchCriteria.validationSchema,

        onSubmit: values => {
            searchCriteria.searchCallback(values)
        },
        
        onReset: values =>{
            searchCriteria.searchCallback({})
        }

    })

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const renderTableRows = (columns, data) =>
        data.map((f, index) => (
            <TableRow
                key={index}
                sx={{
                    '&:last-of-type td, &:last-of-type th': {
                        border: 0
                    }
                }}
            >
                {columns.map((d, index) => CustomTableCell(d.type, f[d.id]))}
            </TableRow>
        ))



    const exportCSV = async () => {
        try {
            let filteredResult = gridData
            if (!_.isEmpty(exportFields)) {
                filteredResult = gridData.map((g) => _.pick(g, ...exportFields))
            }

            const csvresult = await converter.json2csvAsync(filteredResult)
            const hiddenElement = document.createElement("a");
            hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvresult);
            hiddenElement.target = "_blank";
            hiddenElement.download = `${tableName||'export'}_${new Date().toDateString()}.csv`;
            hiddenElement.click();
        } catch (error) {
            console.log("🚩 ~ file: TableBasic.js ~ line 207 ~ exportCSV ~ error", error)
            toast.error(error.message)
        }
    }


    return (
        <Card>
            {searchable ? <SearchFilter noRows={gridData.length < 1} tableName={tableName} formik={formik} filterCritera={searchCriteria.filterCritera} exportCSV={exportCSV} /> : ''}
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <ListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={0}

                        />
                        <TableBody>
                            {renderTableRows(gridColumns, gridData)}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    pagination ?
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onPageChange}
                            onRowsPerPageChange={onRowsPerPageChange}
                        /> : ''
                }
            </Scrollbar>
        </Card>
    )
}

export default TableBasic