import {
    Box,
    CircularProgress,
    Typography,
    CardContent,

} from '@mui/material'
import React from 'react'
import * as yup from 'yup';
import _ from 'lodash';
import { useEffect, useState } from 'react'
import TableBasic from '../../../components/DataTable/TableBasic';

import { toast } from 'react-toastify';
import {useNavigate } from 'react-router-dom'


import { authenticatedInstance } from 'src/utils/axiosInstance';
import Iconify from 'src/components/Iconify';
import useAxios from 'src/hooks/useAxios';
import Loader from 'src/components/Loader';
import ConfirmationDialog from 'src/components/Dialogs/ConfirmationDialog';
import communication from 'src/constants/Communication';

const History = () => {
    const navigate = useNavigate()
    

    const [searchFields, setSearchFields] = useState({})
    const [open, setOpen] = useState(false)
    const [couponData, setCouponData] = useState({})

    const modalData = { title: 'Delete Coupon', content: <p>Are you sure you want to delete <b>{couponData.code}</b> coupon code ?</p> }

    const successCallback = async () => {
        try {
            const response = await authenticatedInstance.request({
                method: 'POST',
                url: '/coupon/deleteCoupon',
                data: {
                    email: couponData.email,
                    code: couponData.code
                }
            })
            handleClose()
            toast.success(response.data.message)
            navigate(0)
        } catch (error) {
            console.log(error)

        }
    }

    const handleClose = () => {

        setOpen(false)
    }

    const handleOpen = (values) => {
        setCouponData(values)
        setOpen(true)
    }

    const [paginationProps, setPaginationProps] = useState({
        page: 0,
        rowsPerPage: 10
    })
    const gridColumns = [
        { label: 'Code', id: 'code', type: 'string' },
        { label: 'Discount', id: 'discount', type: 'string' },
        { label: 'Issued To', id: 'email', type: 'string' },
        { label: 'Groups', id: 'groups', type: 'string' },
        { label: 'Expires', id: 'expires', type: 'datetime' },
        { label: 'isAvailed', id: 'isAvailed', type: 'status' },
        { label: 'Action', id: 'action', type: 'callbackAction' },



    ]

    const handleChangePage = (event, newPage) => {
       
        setPaginationProps((prevState) => ({
            ...prevState,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setPaginationProps((prevState) => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        }));
    };


    const search = (values) => {
        const searchFilter = {}
        for (let key of Object.keys(values)) {
            if (values[key] != '') {
                searchFilter[key] = values[key]
                if (key == 'isPaid') {
                    searchFilter[key] = values[key] == 'PAID' ? true : false
                }
            }
        }

        setSearchFields(searchFilter)
    }

    const searchCriteria = {
        initialValues: {
            code: _.get(searchFields, 'email', ''),
            discount: _.get(searchFields, 'firstName', ''),
            email: _.get(searchFields, 'referenceNo', ''),
        },
        searchCallback: search,
        filterCritera: {
            code: {
                type: 'text',
                name: 'code',
                label: 'Code'
            },
            email: {
                type: 'text',
                name: 'email',
                label: 'Issued Email'
            },

        },
        validationSchema: yup.object({
            code: yup.string(),
            discount: yup.string(),
            email: yup.string(),

        })
    };

    const [data, loading, error] = useAxios({
        method: 'GET',
        url: communication.getCoupons,
        params: { limit: paginationProps.rowsPerPage, page: paginationProps.page + 1, searchCriteria: searchFields },

    })

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <Loader />

    }


    const gridData = data.results.map((f) => {
        return {
            ...f,
            groups:f.groups.map((f)=>f.name).join(','),
            action: {
                icon: <Iconify icon="mdi:delete-circle" sx={{color:'red'}} width={24} height={24} />,
                callback: handleOpen,
                params: {
                    email: f.email,
                    code: f.code
                }
            }

        }
    
    })
    




    





    return (
        <>
            <ConfirmationDialog open={open} handleClose={handleClose} modalData={modalData} successCallback={successCallback} />
            <TableBasic
                tableName={'Coupons'}
                gridColumns={gridColumns}
                gridData={gridData}
                exportFields={['code','discount','isAvailed','isPromotion','expires','email','createdAt','groups']}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={paginationProps.page}
                count={data.totalResults}
                rowsPerPage={paginationProps.rowsPerPage}
                searchCriteria={searchCriteria}
                

            />
        </>

    )
}



export default History
