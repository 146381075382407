import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import UserEdit from './pages/UserEdit';
import Coupons from './pages/Coupons';
import Reports from './pages/Reports/index';
import ReportView from './pages/Reports/View';
import BookingsList from './pages/Bookings/List';
import BookingsView from './pages/Bookings/View';
import PortalList from './pages/Portal/List';
import PortalView from './pages/Portal/View';
// ----------------------------------------------------------------------

export default function Router() {



  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,

      children: [
        { path: 'users', element: <User /> },
        { path: 'useredit/:id', element: <UserEdit /> },
        { path: 'coupons', element: <Coupons /> },
        { path: 'reports', element: <Reports /> },
        { path: 'view-reports/:id', element: <ReportView /> }

      ],
    },
    {
      path: '/bookings',
      element: <DashboardLayout />,

      children:[
        { path: '/bookings', element: <BookingsList /> },
        { path: '/bookings/:id', element: <BookingsView /> },
        
      ],
    },{
      path: '/portal-users',
      element: <DashboardLayout />,

      children:[
        { path: '/portal-users', element: <PortalList /> },
        { path: '/portal-users/:id', element: <PortalView /> },
        
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/users?page=0" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
