import { Grid, CardContent, TextField, Checkbox, FormControl, InputLabel, Select, MenuItem, ListItemText } from '@mui/material'
import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup';
import moment from 'moment';
import LoadingButton from '@mui/lab/LoadingButton';


const IssueCode = ({ issueCouponCallback,groups }) => {

  



    const validationSchema = yup.object({
        discount: yup.number().min(1).max(100).required(),
        expires: yup.date().min(moment()).required(),
        code: yup.string().required(),
        groups:yup.array()
    });

    const formik = useFormik({
        initialValues: {
            discount: 0,
            expires: '',
            code: '',
            groups:[]

        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            values.groups = values.groups
            issueCouponCallback(values, resetForm)
        },

    });

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    return (

        <CardContent>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField InputLabelProps={{
                            shrink: true,
                        }} fullWidth
                            label='Discount'
                            type='number'
                            name='discount'
                            value={formik.values.discount}
                            onChange={formik.handleChange}
                            error={formik.touched.discount && Boolean(formik.errors.discount)}
                            helperText={formik.touched.discount && formik.errors.discount} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth InputLabelProps={{
                            shrink: true,
                        }} label='Discount Validity'
                            type='date'
                            name='expires'
                            value={formik.values.expires}
                            onChange={formik.handleChange}
                            error={formik.touched.expires && Boolean(formik.errors.expires)}
                            helperText={formik.touched.expires && formik.errors.expires} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth InputLabelProps={{
                            shrink: true,
                        }} label='Discount Code'
                            type='text'
                            name='code'
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            error={formik.touched.code && Boolean(formik.errors.code)}
                            helperText={formik.touched.code && formik.errors.code} />
                    </Grid><Grid item xs={12} sm={6}>

                        <FormControl fullWidth>
                            <InputLabel>Groups</InputLabel>
                            <Select name='groups' onChange={formik.handleChange} error={formik.touched.groups && Boolean(formik.errors.groups)}
                                helperText={formik.touched.groups && formik.errors.groups} value={formik.values.groups} multiple label='Groups' MenuProps={MenuProps} renderValue={(selectedValue) => selectedValue.map((f) => groups.find((g) => g.id == f).name).join(',')} >
                                {groups.map((g, index) => (
                                    <MenuItem key={g.name} value={g.id}>
                                        <Checkbox checked={(formik.values.groups || []).indexOf(g.id) > -1} />
                                        <ListItemText primary={g.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>




                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <LoadingButton type='submit' loading={formik.isSubmitting}
                            variant='contained'
                            sx={{ marginBottom: 7 }}>
                            Create
                        </LoadingButton>

                    </Grid>
                </Grid>
            </form>

        </CardContent>

    )
}

export default IssueCode