import _ from "lodash";
import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import TableBasic from "src/components/DataTable/TableBasic";
import Loader from "src/components/Loader";
import communication from "src/constants/Communication";
import useAxios from "src/hooks/useAxios";
import * as yup from 'yup';

export default function Reports() {
    const [searchFields, setSearchFields] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate();

    const [paginationProps, setPaginationProps] = useState({
        page: +searchParams.get('page'),
        rowsPerPage: 10
    })

    const gridColumns = [
        { id: 'name', label: 'Name', alignRight: false, type: 'string' },
        { id: 'description', label: 'Description', alignRight: false, type: 'string' },
        { id: 'type', label: 'Type', alignRight: false, type: 'string' },
        { id: 'createdAt', label: 'Created At', alignRight: false, type: 'datetime' },
        { id: 'action', label: 'Action', alignRight: false, type: 'actionMenu' },
    ];

    const [data, loading, error] = useAxios({
        url: communication.getReports,
        method: 'GET',
        params: { limit: paginationProps.rowsPerPage, page: paginationProps.page + 1, searchCriteria: searchFields, sortBy: 'createdAt:desc' }
    })



    if (loading) {
        return <Loader />
    }

    if (error) {
        return <Loader />

    }

    const search = (values) => {
        const searchFilter = {}
        for (let key of Object.keys(values)) {
            if (values[key] != '') {
                searchFilter[key] = values[key]
            }
        }
        setSearchFields(searchFilter)
        setPaginationProps((prevState) => ({
            ...prevState,
            page: 0
        }));
        setSearchParams({ 'page': 0 })

    }

    const handleChangePage = (event, newPage) => {

        setPaginationProps((prevState) => ({
            ...prevState,
            page: newPage
        }));
        setSearchParams({ 'page': newPage })
    };

    const handleChangeRowsPerPage = (event) => {
        setPaginationProps((prevState) => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        }));
    };

    const handleViewCallback =({id})=>{
        navigate('/dashboard/view-reports/'+id)
    }

    const searchCriteria = {
        initialValues: {
            name: _.get(searchFields, 'name', ''),
            type: _.get(searchFields, 'type', ''),
            id: _.get(searchFields, 'id'),
        },
        searchCallback: search,

        filterCritera: {
            id: {
                type: 'text',
                name: 'id',
                label: 'id'
            },
            name: {
                type: 'text',
                name: 'name',
                label: 'Name'
            },
            type: {
                type: 'text',
                name: 'type',
                label: 'Type',
                label: 'Status',
                options: [{
                    value: '',
                    name: 'ALL'
                },
                {
                    value: 'MongoDB',
                    name: 'MongoDB'
                },
                {
                    value: 'Postgress',
                    name: 'Postgress'
                }]
            },
        },
        validationSchema: yup.object({
            name: yup.string(),
            type: yup.string(),
            id: yup.string(),

        })
    };

    const gridData = data.results.map((f) => ({
        ...f,
        action: {
            menuItems: [
                { params: { id: f.id }, name: "View", callback: handleViewCallback, icon: 'mdi:eye-arrow-right' },
                // { params: { id: f.id }, name: "Edit", callback: (id) => { console.log(id) }, icon: 'mdi:pencil-outline' }
            ]
        }
    }))


   


    return <TableBasic tableName={'Reports'} gridData={gridData} gridColumns={gridColumns} searchCriteria={searchCriteria} onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={paginationProps.page}
        count={data.totalResults}
        rowsPerPage={paginationProps.rowsPerPage} />
}