import React, { useState } from 'react'
import { Card, CardContent, CardHeader, Grid, Box, ImgStyled, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button, Divider, Tab, Tabs, CircularProgress } from "@mui/material"
import { styled } from '@mui/material/styles'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import MuiTab from '@mui/material/Tab'
import { toast } from "react-toastify"
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Iconify from './../../src/components/Iconify'
import BasicDetails from './../../src/sections/@dashboard/user/BasicDetails'
import useAxios from '../../src/hooks/useAxios'
import Loader from './../../src/components/Loader'
import { authenticatedInstance } from './../../src/utils/axiosInstance'
import IssueCode from './../../src/sections/@dashboard/coupons/IssueCode'
import History from './../../src/sections/@dashboard/coupons/History'
import communication from 'src/constants/Communication'

export default function Coupons() {
    const navigate = useNavigate()
    const [value, setValue] = useState('issuecode')

    const Tab = styled(MuiTab)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            minWidth: 100
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 67
        }
    }))

    const TabName = styled('span')(({ theme }) => ({
        lineHeight: 1.71,
        fontSize: '0.875rem',
        marginLeft: theme.spacing(2.4),
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }))
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const issueCouponCallback = async (values, resetForm) => {
        try {
            const response = await authenticatedInstance.request({
                method: 'POST',
                url: '/coupon/issueCoupon',
                data: {
                    isPromotion: true,
                    discount: values.discount,
                    expires: values.expires,
                    promotionCode: values.code,
                    groups: values.groups
                }
            })
            resetForm({
                values: {},
                values: {
                    groups: []
                }
            })
            toast.success(response.data.message)
            router.replace('/')
        } catch (error) {
            console.log(error)

        }
    }

    const [data,loading,error] = useAxios({
        url:communication.getGroups,
        method:'GET',
    })

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <Loader />

    }

    return (
        <Card>
            <TabContext value={value}>
                <TabList
                    onChange={handleChange}
                    aria-label='account-settings tabs'
                    sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
                >
                    <Tab
                        value='issuecode'
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Iconify icon="ri:coupon-3-line" width={24} height={24} />

                                <TabName>Promotion Coupon</TabName>
                            </Box>
                        }
                    />
                    <Tab
                        value='history'
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Iconify icon="ph:books" width={24} height={24} />

                                <TabName>History</TabName>
                            </Box>
                        }
                    />



                </TabList>

                <TabPanel sx={{ p: 0 }} value='issuecode'>
                    <IssueCode groups={data} issueCouponCallback={issueCouponCallback} />
                </TabPanel>
                <TabPanel sx={{ p: 0 }} value='history'>
                    <History/>
                </TabPanel>
            </TabContext>
        </Card>
    )
}
