import _ from "lodash";
import { useState } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import TableBasic from "src/components/DataTable/TableBasic";
import Loader from "src/components/Loader";
import communication from "src/constants/Communication";
import useAxios from "src/hooks/useAxios";
import Exporter from "src/sections/@dashboard/Reports/Exporter";
import { styled } from '@mui/material/styles';
import MuiTab from '@mui/material/Tab'

import * as yup from 'yup';
import Page from "src/components/Page";
import { useFormik } from "formik";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Card } from "@mui/material";
import Iconify from "src/components/Iconify";
import moment from "moment";
import tz from 'moment-timezone';
import { authenticatedInstance } from "src/utils/axiosInstance";
import { toast } from "react-toastify";
import converter from 'json-2-csv'


export default function View() {

    const navigate = useNavigate();
    const { id } = useParams()



    const Tab = styled(MuiTab)(({ theme }) => ({
        [theme.breakpoints.down('md')]: {
            minWidth: 100
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 67
        }
    }))

    const TabName = styled('span')(({ theme }) => ({
        lineHeight: 1.71,
        fontSize: '0.875rem',
        marginLeft: theme.spacing(2.4),
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }))

    const [value, setValue] = useState('export')




    const handleChange = (event, newValue) => {
        setValue(newValue)
    }


    const [currentReport, loading, error] = useAxios({
        url: communication.getReports,
        method: 'GET',
        params: {
            searchCriteria: {
                id: id
            }
        }
    })

    const validationSchema = yup.object({
        filter: yup.string(),
        fromDate: yup.string(),
        toDate: yup.string(),
        allRecords: yup.boolean()

    })

    const handleExportCSV = async (values,setSubmitting) => {
        // tranform fromDate and toDate to mongoDB standards
        let filter = {}
        if(!values.allRecords){
             filter = { createdAt: `gte|date|${values.fromDate}?lte|date|${values.toDate}` }
        }
        
        try {
            const response = await authenticatedInstance.request({
                method: 'POST',
                url: communication.executeQuery,
                data: {
                    id: id,
                    filter: filter
                }
            })

            if(response.data.length > 0){
                await downloadCSV(response.data,currentReport.results[0].name)
                setSubmitting(false)
                return toast.success('Data exported to CSV.')
            }
            setSubmitting(false)
            toast.info('No data found !')
        } catch (error) {
            setSubmitting(false)
            console.log(error)
        }
    }

    const downloadCSV = async (data,name) =>{
        try {
           
            const csvresult = await converter.json2csvAsync(data)
            const hiddenElement = document.createElement("a");
            hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvresult);
            hiddenElement.target = "_blank";
            hiddenElement.download = `${name||'export'}_${new Date().toDateString()}.csv`;
            hiddenElement.click();
        } catch (error) {
            toast.error(error.message)
        }
    }

    const formik = useFormik({
        initialValues: {
            fromDate: moment().subtract(1, 'day').startOf('day').tz('Asia/Karachi').format('YYYY-MM-DDTHH:MM'),
            toDate: moment().startOf('day').tz('Asia/Karachi').format('YYYY-MM-DDTHH:MM'),
            allRecords: false
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {
            handleExportCSV(values,setSubmitting)
        },

    });


    if (loading) {
        return <Loader />
    }

    if (error) {
        return <Loader />

    }


    return <Page title="Reports">

        <Card>
            <TabContext value={value}>
                <TabList
                    onChange={handleChange}
                    aria-label='account-settings tabs'
                    sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
                >
                    <Tab
                        value='export'
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Iconify icon="eva:download-outline" width={24} height={24} />
                                <TabName>Export</TabName>
                            </Box>
                        }
                    />




                </TabList>

                <TabPanel sx={{ p: 0 }} value='export'>
                    <Exporter formik={formik} />
                </TabPanel>

            </TabContext>
        </Card>

    </Page>
}