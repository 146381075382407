import {
    Grid, Box, TextField, ImgStyled, FormControl,
    InputLabel,
    Select,
    MenuItem,
    CardContent,
    FormControlLabel,
    Checkbox,
    Button,
    Typography,
    Chip,
    CircularProgress,
    OutlinedInput,
    ListItemText,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import * as yup from 'yup';
import moment from 'moment'
import React from 'react'


const BasicDetails = ({ userDetails, updateCallback, groups }) => {
    const ImgStyled = styled('img')(({ theme }) => ({
        width: 120,
        height: 120,
        marginRight: theme.spacing(6.25),
        borderRadius: theme.shape.borderRadius
    }))

    const validationSchema = yup.object({
        firstName: yup.string(),
        whatsapp: yup.string(),
        isPaid: yup.boolean(),
        groups: yup.array(),
        gender: yup.string(),

    });

    const formik = useFormik({
        initialValues: {
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            whatsapp: userDetails.whatsapp,
            isPaid: userDetails.isPaid,
            groups: userDetails.groups,
            gender: userDetails.gender

        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            values.groups = values.groups
            updateCallback(values, resetForm)

        },

    });


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const genders = [
        { name: "Male", value: 'Male' },
        { name: "Female", value: 'Female' }

    ]


    return (
        <CardContent>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ marginTop: 4.8, marginBottom: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ImgStyled src={userDetails.gender == 'Male'?'/static/avatars/3.png':'/static/avatars/4.png'} alt='Profile Pic' />
                            <Box>
                                <Typography variant='h6'>{userDetails.firstName + " " + userDetails.lastName}</Typography>
                                <Chip sx={{borderRadius:1}} label={userDetails.isForeigner ? 'Foreigner' : 'Local'} color='info' />

                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label='First Name' value={formik.values.firstName}
                            name='firstName'
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label='Last Name' value={formik.values.lastName}
                            name='lastName'
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField fullWidth label='Age' value={userDetails.age} disabled />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField fullWidth label='Class' value={userDetails.class} disabled />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField fullWidth label='School' value={userDetails.school} disabled />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                           
                            <TextField
                                
                                label='Gender'
                                value={formik.values.gender}
                                name='gender'
                                onChange={formik.handleChange}
                                error={formik.touched.gender && Boolean(formik.errors.gender)}
                                helperText={formik.touched.gender && formik.errors.gender}
                                select
                                fullWidth
                            >
                                {genders.map(d => (
                                    <MenuItem value={d.value}>{d.name}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label='Reference' value={userDetails.refrence} disabled />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label='Whatsapp' value={formik.values.whatsapp}
                            name='whatsapp'
                            onChange={formik.handleChange}
                            error={formik.touched.whatsapp && Boolean(formik.errors.whatsapp)}
                            helperText={formik.touched.whatsapp && formik.errors.whatsapp} />

                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField fullWidth label='Created At' value={moment(userDetails.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY')} disabled />

                    </Grid>
                    <Grid item xs={12} sm={3}>

                        <FormControl fullWidth>
                            <InputLabel>Groups</InputLabel>
                            <Select name='groups' onChange={formik.handleChange} error={formik.touched.groups && Boolean(formik.errors.groups)}
                                helperText={formik.touched.groups && formik.errors.groups} value={formik.values.groups} multiple label='Groups' MenuProps={MenuProps} renderValue={(selectedValue) => selectedValue.map((f) => groups.find((g) => g.id == f).name).join(',')} >
                                {groups.map((g, index) => (
                                    <MenuItem key={g.name} value={g.id}>
                                        <Checkbox checked={(formik.values.groups || []).indexOf(g.id) > -1} />
                                        <ListItemText primary={g.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>




                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField fullWidth label='Country' value={userDetails.country} disabled />

                    </Grid>


                    <Grid item xs={12} sm={3}>
                        <InputLabel>Status</InputLabel>
                        <FormControlLabel control={<Checkbox color='success' checked={formik.values.isPaid} />}
                            label="Paid"
                            name='isPaid'
                            onChange={formik.handleChange}
                            error={formik.touched.isPaid && Boolean(formik.errors.isPaid)}
                            helperText={formik.touched.isPaid && formik.errors.isPaid}
                        />
                    </Grid>



                    <Grid item xs={12} sm={6}>
                        <Button type='submit' variant='contained' sx={{ marginRight: 3.5 }}>
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </CardContent>
    )
}

export default BasicDetails